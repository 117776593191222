import React, { useEffect } from "react";

class HoverControlsElement extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: "open" });

        this.shadowRoot.innerHTML = `
      <style>
        .container {
          position: absolute;
          display: none;
          pointer-events: none;
          outline: #005BD3 solid 2px;
          outline-offset: -2px;
          z-index: 9999;
          overflow: visible;
        }

        .add-btn {
          position: absolute;
          width: 20px;
          height: 20px;
          color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.3s;
          pointer-events: all;
        }

        .add-btn:hover svg {
          display: none;
        }

        .add-btn:hover button {
          display: block;
        }

        button {
            border-radius: 8px;
            background: #303030;
            padding: 6px 9px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            color: #fff;
            position: relative;
            border: transparent;
            cursor: pointer;
            white-space: nowrap;
            transition: opacity 0.2s;
            display: none;
        }
        button:before {
            content: "";
            border-radius: 9px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 82.14%, rgba(255, 255, 255, 0.15) 94.44%);
            box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.20) inset, 2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, -2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1px 0px 1px #000 inset, 0px 1px 0px 0px #000 inset;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        } 
            .tools {
                display: inline-flex;
                align-items: center;
                    gap: 10px;
                background: #202223;
                padding: 7px;
                border-radius: 8px;
                box-shadow: 0 0 .25rem #0000001a, 0 .5rem 2.5rem #0003;
                line-height: 0;
                position: absolute;
                top: 5px;
                right: 5px;
                pointer-events: all;
            }
                .tools svg {
                fill: #a7aaad;
                    width: 20px;
    height: 20px;
                }
    .tools svg.wh-16 {
        width: 16px;
        height: 16px;
    }
        .tools div {
            border-radius: 5px;
            background: transparent;
            transition: background 0.3s;
            padding: 2px;
            cursor: pointer;
        }
        .tools div:hover {
        background: #303133;
        }

        .tools div.delete svg {
            fill:#f56b6b;
        }

        .tools div.disabled{
            opacity: 0.3;
            pointer-events: none;
            cursor: not-allowed;
        }
            .tools div.disabled:before {
                content: "";
    height: 1px;
    width: 16px;
    background: #a7aaad;
    display: block;
    transform: rotate(-45deg);
    position: relative;
    top: 12px;
    left: 4px;
            }
    .tools div.delete.disabled:before {
        top: 8px;
    left: 0px;
    }
    .tools div.move-down.disabled:before {
        top: 11px;
    left: 0px;    
    }
    .section-label{
            background: #005BD3;
    display: inline-block;
    color: #fff;
    font-size: 11px;
    padding: 3px 5px;
    border-radius: 4px 4px 0 0;
    font-family: "Inter";
    position: relative;
    top: -19px;
    }
    .section-label.top{
            top: 1px;
    border-radius: 0 0 4px 4px;
    }
      </style>
      <div class="container">
      <div class="section-label">
        Header 1-1
      </div>
        <div class="add-btn" id="top-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" rx="10" fill="#005BD3"/>
            <path d="M10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75L9.25 9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25L9.25 13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="white"/>
          </svg>
          <button>Add section</button>
        </div>
        <div class="add-btn" id="bottom-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" rx="10" fill="#005BD3"/>
            <path d="M10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75L9.25 9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25L9.25 13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="white"/>
          </svg>
          <button>Add section</button>
        </div>
        
        <div class="tools">
            <div class="move-up">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M18 16a.75.75 0 0 1-.75.75h-6.5a.75.75 0 0 1 0-1.5h6.5A.75.75 0 0 1 18 16ZM18 7a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1 0-1.5h4.5A.75.75 0 0 1 18 7ZM4.53 8.28a.75.75 0 1 1-1.06-1.06l2.5-2.5a.75.75 0 0 1 1.06 0l2.5 2.5a.75.75 0 0 1-1.06 1.06L7.25 7.06V8.5a2.25 2.25 0 0 0 2.25 2.25h7.75a.75.75 0 0 1 0 1.5H9.5A3.75 3.75 0 0 1 5.75 8.5V7.06L4.53 8.28Z"></path></svg>
            </div>
            <div class="move-down">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" focusable="false"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.25a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 2 5.25Zm0 9a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Zm14.53-1.28a.75.75 0 0 0-1.06 0l-1.22 1.22v-1.44A3.75 3.75 0 0 0 10.5 9H2.75a.75.75 0 0 0 0 1.5h7.75a2.25 2.25 0 0 1 2.25 2.25v1.44l-1.22-1.22a.75.75 0 1 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0l2.5-2.5a.75.75 0 0 0 0-1.06Z"></path></svg>
            </div>
            <div class="show-hide">
                <svg class="wh-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.977 2.751a7.6 7.6 0 0 0-1.977-.251c-2.444 0-4.196 1.045-5.325 2.233a7.2 7.2 0 0 0-1.243 1.773c-.26.532-.432 1.076-.432 1.494s.171.962.432 1.494c.172.353.4.733.687 1.115l1.074-1.074a5 5 0 0 1-.414-.7c-.221-.453-.279-.753-.279-.835s.058-.382.279-.835a5.7 5.7 0 0 1 .983-1.398c.89-.937 2.264-1.767 4.238-1.767q.36 0 .693.036z"></path><path fill-rule="evenodd" d="M2.25 12.6a.75.75 0 0 0 1.067 1.053l1.062-1.061c.975.543 2.177.908 3.621.908 2.45 0 4.142-1.05 5.24-2.242 1.078-1.17 1.588-2.476 1.738-3.076a.75.75 0 0 0 0-.364c-.15-.6-.66-1.906-1.738-3.076a7 7 0 0 0-.51-.502l.923-.923a.749.749 0 0 0-1.053-1.068l-.008.008-10.335 10.336zm5.75-.6c-.978 0-1.809-.204-2.506-.523l1.108-1.109a2.75 2.75 0 0 0 3.767-3.766l1.298-1.299q.254.221.47.455a6.4 6.4 0 0 1 1.332 2.242 6.4 6.4 0 0 1-1.332 2.242c-.86.933-2.17 1.758-4.137 1.758m0-2.75q-.13-.001-.254-.026l1.478-1.478a1.25 1.25 0 0 1-1.224 1.504"></path></svg>
            </div>
            <div class="delete">
                <svg class="wh-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.5 6.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75"></path><path d="M7.25 7a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0z"></path><path fill-rule="evenodd" d="M5.25 3.25a2.75 2.75 0 1 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5zm1.5 0a1.25 1.25 0 0 1 2.5 0zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848z"></path></svg>
            </div>
        </div>
      </div>
    `;

        this.container = this.shadowRoot.querySelector(".container");
        this.topBtn = this.shadowRoot.getElementById("top-btn");
        this.bottomBtn = this.shadowRoot.getElementById("bottom-btn");
        this.tools = this.shadowRoot.querySelector(".tools");

        this.hideTimeout = null;

        // Hover vào dấu + để hiện
        [this.topBtn, this.bottomBtn].forEach((btn) => {
            btn.addEventListener("mouseenter", () => this.cancelHide());
            btn.addEventListener("mouseleave", () => this.handleLeave());
        });

        // Hover vào tools để không bị ẩn
        this.tools.addEventListener("mouseenter", () => this.cancelHide());
        this.tools.addEventListener("mouseleave", () => this.handleLeave());

        // Gửi sự kiện click lên React
        this.topBtn.addEventListener("click", () => this.handleAddSection("top", this.sectionId, this.group));
        this.bottomBtn.addEventListener("click", () => this.handleAddSection("bottom", this.sectionId, this.group));

        this.deleteBtn = this.tools.querySelector('.delete');
        if (this.deleteBtn) {
            this.deleteBtn.addEventListener("click", () => this.handleDeleteSection(this.sectionId, this.group));
        }

        this.hideBtn = this.tools.querySelector('.show-hide');
        if (this.hideBtn) {
            this.hideBtn.addEventListener("click", () => this.handleHideSection(this.sectionId, this.group));
        }

        this.moveUpBtn = this.tools.querySelector('.move-up');
        if (this.moveUpBtn) {
            this.moveUpBtn.addEventListener("click", () => this.handleMoveUpSection(this.sectionId, this.group));
        }

        this.moveDownBtn = this.tools.querySelector('.move-down');
        if (this.moveDownBtn) {
            this.moveDownBtn.addEventListener("click", () => this.handleMoveDownSection(this.sectionId, this.group));
        }
    }

    show(target) {
        if (target) {
            clearTimeout(this.hideTimeout);
            const rect = target.getBoundingClientRect();
            const computedStyle = window.getComputedStyle(target);
            this.sectionId = target.id;

            const allSections = document.querySelectorAll(".builder-preview .section");
            const indexTarget = Array.from(allSections).indexOf(target);

            const labelEl = this.container.querySelector('.section-label');

            if(indexTarget === 0) {
                labelEl.classList.add('top')
            } else {
                labelEl.classList.remove('top')
            }

            if (labelEl) {
                labelEl.textContent = target.dataset.name;
            }

            let group = 'template';
            if (target?.closest('#header-group')) {
                group = 'header';
            }
            if (target?.closest('#footer-group')) {
                group = 'footer';
            }
            if (target?.closest('#global-group')) {
                group = 'global';
            }
            this.group = group;

            const marginTop = parseFloat(computedStyle.marginTop) || 0;
            const marginBottom = parseFloat(computedStyle.marginBottom) || 0;

            const sectionTop = window.scrollY + rect.top - marginTop;
            const sectionHeight = rect.height + marginTop + marginBottom;

            this.container.style.display = "block";
            this.tools.style.display = "inline-flex";
            this.container.style.top = `${sectionTop}px`;
            this.container.style.left = `${window.scrollX + rect.left}px`;
            this.container.style.width = `${rect.width}px`;
            this.container.style.height = `${sectionHeight}px`;

            this.topBtn.style.top = "-10px";
            this.topBtn.style.left = "50%";
            this.topBtn.style.transform = "translateX(-50%)";

            this.bottomBtn.style.bottom = "-9px";
            this.bottomBtn.style.left = "50%";
            this.bottomBtn.style.transform = "translateX(-50%)";

            //Disable button delete nếu template group có 1 item
            let allTemplateSections = document.querySelectorAll("#template-group .section");
            if (!allTemplateSections.length) {
                allTemplateSections = document.querySelectorAll(".builder-preview>.section");
            }

            this.deleteBtn = this.tools.querySelector('.delete');

            if (this.deleteBtn) {
                if (this.group === 'template') {
                    if (allTemplateSections.length === 1) {
                        this.deleteBtn.classList.add('disabled');
                    } else {
                        this.deleteBtn.classList.remove('disabled');
                    }
                } else {
                    this.deleteBtn.classList.remove('disabled');
                }
            }

            let wrapper = target.closest('.preview-group');
            if (!wrapper) {
                wrapper = target.closest('.builder-preview');
            }
            if (wrapper) {
                const listSections = wrapper.querySelectorAll(".section");
                const index = Array.from(listSections).indexOf(target);
                this.moveUpBtn = this.tools.querySelector('.move-up');
                this.moveDownBtn = this.tools.querySelector('.move-down');
                if (this.moveUpBtn) {
                    if (index === 0) {
                        this.moveUpBtn.classList.add('disabled');
                    } else {
                        this.moveUpBtn.classList.remove('disabled');
                    }
                }

                if (this.moveDownBtn) {
                    if (index === listSections.length - 1) {
                        this.moveDownBtn.classList.add('disabled');
                    } else {
                        this.moveDownBtn.classList.remove('disabled');
                    }
                }
            }
        }
    }

    hide() {
        this.hideTimeout = setTimeout(() => {
            this.container.style.display = "none";
        }, 50); // Trì hoãn ẩn 200ms để tránh nhấp nháy
    }

    handleLeave() {
        this.hide();
    }

    cancelHide() {
        clearTimeout(this.hideTimeout);
    }

    handleAddSection(position, sectionId, group) {
        const event = new CustomEvent("add-section", {
            detail: { position, sectionId, group },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);
    }

    handleDeleteSection(sectionId, group) {
        const event = new CustomEvent("delete-section", {
            detail: { sectionId, group },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);
        this.hide();
    }

    handleHideSection(sectionId, group) {
        const event = new CustomEvent("hide-section", {
            detail: { sectionId, group },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);
        this.hide();
    }

    handleMoveUpSection(sectionId, group) {
        const event = new CustomEvent("moveup-section", {
            detail: { sectionId, group },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);

        //this.container.style.display = "none";
        //this.tools.style.display = "none";
        this.hide();
    }

    handleMoveDownSection(sectionId, group) {
        const event = new CustomEvent("movedown-section", {
            detail: { sectionId, group },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);

        //this.container.style.display = "none";
        //this.tools.style.display = "none";
        this.hide();
    }
}

if (!customElements.get("hover-controls")) {
    customElements.define("hover-controls", HoverControlsElement);
}

const HoverControls = ({ active, onAddSection, onDeleteSection, onHideSection, onMoveUpSection, onMoveDownSection }) => {
    useEffect(() => {
        const hoverControls = document.createElement("hover-controls");
        document.body.appendChild(hoverControls);

        const attachEvents = () => {
            document.querySelectorAll(".section").forEach((section) => {
                if (!section.dataset.hoverEventAdded) {
                    section.dataset.hoverEventAdded = "true";
                    section.addEventListener("mouseenter", function () {
                        this.classList.remove('move');
                        this.classList.remove('active');
                        hoverControls.show(this);
                    });

                    section.addEventListener("mouseleave", function () {
                        this.classList.remove('move');
                        this.classList.remove('active');
                        hoverControls.hide();
                    });
                }
            });
        };

        attachEvents();

        const observer = new MutationObserver(() => {
            attachEvents();
        });

        observer.observe(document.body, { childList: true, subtree: true });

        const handleAddSection = (event) => {
            const position = event.detail.position;
            const sectionId = event.detail.sectionId;
            const group = event.detail.group;
            if (position && sectionId && group) {
                onAddSection(sectionId, position, group);
            }
        };

        const handleDeleteSection = (event) => {
            const sectionId = event.detail.sectionId;
            const group = event.detail.group;
            if (sectionId && group) {
                onDeleteSection(sectionId, group);
            }
        };

        const handleHideSection = (event) => {
            const sectionId = event.detail.sectionId;
            const group = event.detail.group;
            if (sectionId && group) {
                onHideSection(sectionId, group);
            }
        };

        const handleMoveUpSection = (event) => {
            const sectionId = event.detail.sectionId;
            const group = event.detail.group;
            if (sectionId && group) {
                onMoveUpSection(sectionId, group);
            }
        };

        const handleMoveDownSection = (event) => {
            const sectionId = event.detail.sectionId;
            const group = event.detail.group;
            if (sectionId && group) {
                onMoveDownSection(sectionId, group);
            }
        };

        hoverControls.addEventListener("add-section", handleAddSection);
        hoverControls.addEventListener("delete-section", handleDeleteSection);
        hoverControls.addEventListener("hide-section", handleHideSection);
        hoverControls.addEventListener("moveup-section", handleMoveUpSection);
        hoverControls.addEventListener("movedown-section", handleMoveDownSection);

        return () => {
            observer.disconnect();
            hoverControls.remove();
            hoverControls.removeEventListener("add-section", handleAddSection);
            hoverControls.removeEventListener("delete-section", handleDeleteSection);
            hoverControls.removeEventListener("hide-section", handleHideSection);
            hoverControls.removeEventListener("moveup-section", handleMoveUpSection);
            hoverControls.removeEventListener("movedown-section", handleMoveDownSection);
        };
    }, []);

    useEffect(() => {
        if(!!active) {
           const hoverControls = document.querySelector("hover-controls");
           if(hoverControls) {
              hoverControls.classList.remove('hidden');
           }
        } else {
            const hoverControls = document.querySelector("hover-controls");
            if(hoverControls) {
               hoverControls.classList.add('hidden');
            }
        }
    }, [active])

    return null;
};

export default HoverControls;
